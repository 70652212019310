import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

import Layout from '../components/layout'
import SEO from '../components/seo'

const CoursesPage = () => {
	const data = useStaticQuery(graphql`
		query {
			logoBb: file(relativePath: { eq: "logo/logo-blackbridge.png" }) {
				childImageSharp {
					fixed(width: 200) {
						...GatsbyImageSharpFixed
					}
				}
			}
			logoZbraslav: file(relativePath: { eq: "logo/logo-zbraslav.png" }) {
				childImageSharp {
					fixed(width: 200) {
						...GatsbyImageSharpFixed
					}
				}
			}
			logoLoreta: file(relativePath: { eq: "logo/logo-pysely.png" }) {
				childImageSharp {
					fixed(width: 200) {
						...GatsbyImageSharpFixed
					}
				}
			}
			logoMstetice: file(relativePath: { eq: "logo/logo-mstetice.png" }) {
				childImageSharp {
					fixed(width: 200) {
						...GatsbyImageSharpFixed
					}
				}
			}
			logoBerovice: file(relativePath: { eq: "logo/logo-berovice.png" }) {
				childImageSharp {
					fixed(width: 200) {
						...GatsbyImageSharpFixed
					}
				}
			}
		}
	`)

	return (
		<Layout>
			<SEO title="Golf courses" />
			<div className="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
				<h1 className="display-4 mb-4">Golf courses</h1>
				<p className="lead mb-5">
					Five top golf resorts in Prague are involved.
				</p>

				<div className="row text logos-battery">
					<div className="col-sm-4 mb-5">
						<OutboundLink href="https://www.grcm.cz/en/">
							<Img fixed={data.logoBb.childImageSharp.fixed} />
						</OutboundLink>
					</div>
					<div className="col-sm-4 mb-5">
						<OutboundLink href="https://www.praguecitygolf.cz/">
							<Img fixed={data.logoZbraslav.childImageSharp.fixed} />
						</OutboundLink>
					</div>
					<div className="col-sm-4 mb-5">
						<OutboundLink href="http://www.loretagolf.cz/">
							<Img fixed={data.logoLoreta.childImageSharp.fixed} />
						</OutboundLink>
					</div>
					<div className="col-sm-4 mb-5">
						<OutboundLink href="https://www.gcbr.cz/">
							<Img fixed={data.logoBerovice.childImageSharp.fixed} />
						</OutboundLink>
					</div>
					<div className="col-sm-4 mb-5">
						<OutboundLink href="https://www.golfmstetice.cz/">
							<Img fixed={data.logoMstetice.childImageSharp.fixed} />
						</OutboundLink>
					</div>
				</div>

				<p className="mt-5">
					<Link
						to="/map/"
						className="btn btn-lg btn-outline-success"
						role="button"
					>
						Display the map
					</Link>
				</p>
			</div>

			<hr className="featurette-divider" />
		</Layout>
	)
}

export default CoursesPage
